import React from 'react';
import { Helmet } from 'react-helmet';
import MainLayout from '../layouts/MainLayout';
import { graphql } from 'gatsby';
import { css } from 'styled-components';
import Header, {
  HeaderContentProps,
} from '../components/pageBuilder/Elements/Header';
import CtaButtonRow, {
  CtaButtonRowProps,
} from '../components/pageBuilder/Elements/CtaButtons';
import Icon from '@legacy-vfuk/core-icon';
import Block from '@legacy-vfuk/core-block';
import SimpleGrid from '@legacy-vfuk/core-simple-grid';

type SectionIconLinkBarProps = {
  __typename: 'ContentfulSectionIconLinkBar';
  id: string;
  name: string;
  isLeading?: boolean;
  isTrailing?: boolean;
  icons: [
    {
      id: string;
      name: string;
      label?: string;
      url: string;
      iconRef: string;
      color: string;
    }
  ];
};

type ContentArrayProps =
  | SectionIconLinkBarProps
  | CtaButtonRowProps
  | HeaderContentProps;

export type ConfirmationPageProps = {
  data: {
    contentfulSectionConfirmationPage: {
      id: string;
      name: string;
      slug: string;
      seoTitle: string;
      content: ContentArrayProps[];
      seoEventData: {
        seoEventData: string;
      };
    };
  };
  location: any;
};

const transparentCSS = css`
  background-color: transparent;
`;

const ConfirmationPage = ({
  data: { contentfulSectionConfirmationPage },
  location
}: ConfirmationPageProps) => {
  const { seoTitle, content } = contentfulSectionConfirmationPage;

  const sectionElements = content.map(sectionItem => {
    switch (sectionItem.__typename) {
      case 'ContentfulSectionElementHeader': {
        const { id, ...content } = sectionItem;
        return <Header key={id} content={content} />;
      }
      case 'ContentfulSectionIconLinkBar': {
        return sectionItem?.icons?.map(icon => {
          return (
            <div
              key={icon.id}
              className="IconSvg-wrapper"
              css={css`
                color: ${icon.color};
                text-align: center;
                margin-bottom: 30px;
              `}
            >
              <Icon
                key={icon.id}
                name={icon.iconRef}
                group="midLight"
                size={5}
              />
            </div>
          );
        });
      }
      case 'ContentfulSectionElementCtaButtonRow': {
        const { id, buttonRowAlignment, buttons } = sectionItem;
        return (
          <div
            key={id}
            css={css`
              margin-top: 40px;
            `}
          >
            <SimpleGrid
              justify={'center'}
              columns={{
                sm: 1
              }}
            >
              <CtaButtonRow
                buttonRowAlignment={buttonRowAlignment}
                buttons={buttons}
              />
            </SimpleGrid>
          </div>
        );
      }
    }
  });

  // Trigger the SEO event data.
  const runConfirmationPageSeoEvent = () => {
    const seoEventData =
      contentfulSectionConfirmationPage.seoEventData.seoEventData;
    if (typeof onConfirmationPageUserEvent === 'function' && seoEventData) {
      try {
        const payload = JSON.parse(seoEventData);
        const { type, data } = payload;

        window.onConfirmationPageUserEvent(type, data);
      } catch (e) {
        console.log(
          'onConfirmationPageUserEvent failed - please check the Filter SEO script JSON and that a SeoInteractionScript is correctly set up.'
        );
      }
    }
  };

  runConfirmationPageSeoEvent();

  return (
    <MainLayout locationPathname={location.pathname}>
      {seoTitle && (
        <Helmet>
          <title>{seoTitle}</title>
        </Helmet>
      )}
      <Block className="section" css={transparentCSS}>
        <SimpleGrid
          columns={{
            sm: 1
          }}
        >
          <div className="Spring">{sectionElements}</div>
        </SimpleGrid>
      </Block>
    </MainLayout>
  );
};

export const query = graphql`
  query getConfirmationPage($slug: String) {
    contentfulSectionConfirmationPage(slug: { eq: $slug }) {
      id
      name
      slug
      seoTitle
      content {
        __typename
        ... on ContentfulSectionIconLinkBar {
          id
          name
          isLeading
          isTrailing
          icons {
            id
            name
            label
            url
            iconRef
            color
          }
        }
        ... on ContentfulSectionElementHeader {
          id
          name
          headerText
          appearance
          inverse
          justify
          level
          noMargin
          weight
        }
        ... on ContentfulSectionElementCtaButtonRow {
          id
          buttonRowAlignment
          buttons {
            id
            label
            url
            rank
            iconRef
            seoEventData {
              seoEventData
            }
          }
        }
      }
      seoEventData {
        seoEventData
      }
    }
  }
`;

export default ConfirmationPage;
